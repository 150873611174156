
export const Actions = {
    
    //... bak for references
    // , UPDATE_ORDER: 'UPDATE_ORDER'
    // , updateOrder: order => { return { type: Actions.UPDATE_ORDER, order } }

      SET_STABLE: 'SET_STABLE'
    , SET_DYNAMIC: 'SET_DYNAMIC'
     
    , setPrompt: prompt => { return { type: Actions.SET_DYNAMIC, prompt } }
    /** //___ asdasd
     * @param {string|boolean} lockScreen */
    , setLockScreen: lockScreen => { return { type: Actions.SET_DYNAMIC, lockScreen, lockSceenStart: +new Date() } }
    , unLockScreen: () => { return { type: Actions.SET_DYNAMIC, lockScreen: false, lockSceenStart: undefined } }
    , setScreen: (screen) => { return { type: Actions.SET_DYNAMIC, curScreen: screen } }

    , setCred: cred => { /* L.f('setCred', cred); */ return { type: Actions.SET_STABLE, cred } }
    , setProfile: (profile, processor) => { profile = processor?.(profile) ?? profile; return { type: Actions.SET_STABLE, profile } }

    , reH2Oed: data => { /* L.dim('Action reH2Oed', data); */ return { type: Actions.SET_STABLE, ...data } }

    , setUnitList: (unitList) => { return { type: Actions.SET_DYNAMIC, unitList } }
    , setAttackList: (attackList) => { return { type: Actions.SET_DYNAMIC, attackList } }
    
    , setSelectedUnit: (selectedUnit) => { return { type: Actions.SET_DYNAMIC, selectedUnit } }

    , setLastSelectedAlert: (lastSelectedAlert) => { return { type: Actions.SET_STABLE, lastSelectedAlert } }
    
    , setEditingAlert: (editingAlert) => { return { type: Actions.SET_DYNAMIC, editingAlert } }
    , setLastEditingAlert: (lastEditingAlert) => { return { type: Actions.SET_STABLE, lastEditingAlert } }
    , setLastPreview: (lastPreview) => { return { type: Actions.SET_STABLE, lastPreview } }
}