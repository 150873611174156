import React, { createContext, useReducer } from 'react';
import { Actions } from './actions';

/**
 * Enum for Screens
 * @readonly
 * @enum {string} */
 export var SCREEN = { MY_SIGNALS: "MY_SIGNALS", COMMUNITY: "COMMUNITY", CONFIGS: "CONFIGS" };

const debug = { lastActionType: '', lastCompressedStr: '' };

const initialState = { 
    curScreen: SCREEN.MY_SIGNALS
};

export const DynamicContext = createContext(initialState);
const { Provider } = DynamicContext;

const initializer = async (initialValue = initialState) =>
{
    //& no storing
    let initrs = initialValue // JSON.parse(await AsyncStorage.getItem('persistDynamic')) || initialValue;
    return initrs;
}


export const DynamicProvider = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        let newState = state;
        // console.log(`EX-PO store: ${action.type}`);
        debug.lastActionType = action.type;
        switch (action.type) {
            //... case Actions.SET_STAFFS: newState = { ...state, staffs: action.staffs }; break;
            //... case Actions.SET_MULTIPLES:  newState ={ ...state, ...action.datas, type: undefined }; break;
            case Actions.SET_DYNAMIC: newState = { ...state, ...{ ...action, type: undefined } }; break;
            default: newState = state; break;
        };
        return newState;
    }
    , initialState, initializer);


    //& no storing
    // useEffect(() => {
    //     AsyncStorage.setItem("persistDynamic", JSON.stringify(state));
    // }
    // , [state]);
    

    return <Provider value={{ state, dDispatch: dispatch }}>{children}</Provider>;
};