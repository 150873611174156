import { useContext, useState } from "react";
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList, Switch } from 'react-native';
import { ConfigsEditor } from "./components/ConfigsEditor";
import { UnitEditor } from "./components/UnitEditor";
import { UnitList } from "./components/UnitList";
import { DynamicContext } from "./contexts/DynamicContext";
import { UnitAttacks } from "./components/UnitAttacks";

const MODE_UNITS = "MODE_UNITS";
const MODE_CONFIGS = "MODE_CONFIGS";

export default function ActualApp(props) {

    const dContext = useContext(DynamicContext); 
    const { dDispatch } = dContext;
    
    const { selectedUnit, unitList } = dContext?.state;
    
    const [ reload, setReload ] = useState(0);
    const [ mode, setMode ] = useState(localStorage.getItem("LAST_MODE") ?? MODE_UNITS);

    let isUnitMode = mode == MODE_UNITS;
    let isConfigsMode = mode == MODE_CONFIGS;

    const switchMode = () => {
        var newMode = isUnitMode ? MODE_CONFIGS : isConfigsMode ? MODE_UNITS : MODE_UNITS;
        setMode(newMode);
        localStorage.setItem("LAST_MODE", newMode);
    }

    return (
        <View nativeID="ActualApp" style={styles.pageParent} >


            <View nativeID="Tabs" style={[styles.page]} >
                <TouchableOpacity style={[styles.tab, isUnitMode ? styles.tabSelected : undefined ]} onPress={() => { switchMode(); }} >
                    <Text style={[styles.buttonTxt, { color: 'black' } ]} >{"Units"}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.tab, isConfigsMode ? styles.tabSelected : undefined ]} onPress={() => { switchMode(); }} >
                    <Text style={[styles.buttonTxt, { color: 'black' } ]} >{"Configs"}</Text>
                </TouchableOpacity>
            </View>

            { !isUnitMode ? null :
                <View nativeID="Units" style={styles.page} >
                    <UnitList reload={reload} dDispatch={dDispatch} selectedUnit={selectedUnit} unitList={unitList} appStyles={styles}/>
                    { selectedUnit == null ? null :
                        <UnitEditor dDispatch={dDispatch} selectedUnit={selectedUnit} appStyles={styles} reload={reload} setReload={setReload} />
                    }
                    { selectedUnit == null /* || !!selectedUnit?.Data */ ? null :
                        <UnitAttacks dDispatch={dDispatch} selectedUnit={selectedUnit} appStyles={styles} reload={reload} setReload={setReload} hasData={!!selectedUnit?.Data} />
                    }
                </View>
            }
            { !isConfigsMode ? null :
                <View nativeID="Gameplays" style={styles.page} >
                    <ConfigsEditor dDispatch={dDispatch} appStyles={styles} />
                </View>
            }
        </View>
    );
    
}


const styles = StyleSheet.create({
    pageParent: {
        flexDirection: 'column'
    },
    page: {
        flexDirection: 'row'
    },
    button: {
        // @ts-ignore
        borderRadius: '8mm',
        backgroundColor: '#009688', paddingVertical: '2mm', paddingHorizontal: '4mm',
        justifyContent: 'center', alignItems: 'center', marginVertical: '2mm'
    },
    tab: {
        // @ts-ignore
        borderTopLeftRadius: '8mm', borderTopRightRadius: '8mm',
        backgroundColor: '#999', paddingVertical: '2mm', paddingHorizontal: '4mm',
        justifyContent: 'center', alignItems: 'center', marginVertical: '2mm',
        minWidth: '5cm'
    },
    tabSelected: {
        backgroundColor: 'white', borderColor: 'black', borderWidth: 1, borderBottomWidth: 0
    },
    tabSelectedTxt: {
        color: 'black'
    },
    buttonTxt: {
        color: '#fff', fontWeight: 'bold'
    },
    H2: {
        fontSize: 14, fontWeight: 'bold', color: '#444'
    },
    H1: {
        fontSize: 18, fontWeight: 'bold', color: '#444'
    },
    fieldTitle: {
        fontSize: 9, fontWeight: 'bold', color: '#444', marginTop: '1mm'
    },
    field: {
        paddingHorizontal: '2mm', paddingVertical: '0.5mm', borderColor: '#444', borderBottomWidth: 1,
        marginBottom: '3mm', maxWidth: '3cm'
    },
    fieldShort: {
        paddingHorizontal: '2mm', paddingVertical: '1mm', borderColor: '#444', borderBottomWidth: 1,
        marginBottom: '3mm', maxWidth: '1.5cm'
    },
    fieldLong: {
        paddingHorizontal: '2mm', paddingVertical: '1mm', borderColor: '#444', borderBottomWidth: 1,
        marginBottom: '3mm'
    },
    form: {
        paddingVertical: '1mm',
        paddingHorizontal: '6mm',
        // @ts-ignore
        borderRadius: '2mm',
        borderWidth: 1, borderColor: 'gray', 
        marginVertical: '2mm'
    },
    space: {
        width: '6mm', height: '6mm'
    },
    fieldTitleBig: {
        fontSize: 12, fontWeight: 'bold', color: '#555'
    },
    fieldTitleBigger: {
        fontSize: 16, fontWeight: '900', color: '#222'
    }
});