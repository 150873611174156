
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList, Switch } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';
import api from '../api/api';
import { Actions } from '../contexts/actions';



export const MToogle = (props) => {

    const { label, value, onValueChange } = props;

    return (
        <View style={[ styles.form ]} >
            <Switch style={styles.switch} value={value} onValueChange={onValueChange} />
            { !(0 < label?.length) ? null :
                <Text style={styles.fieldTitle} >{label}</Text>            
            }                
        </View>
    );

}



const styles = StyleSheet.create({
    form: {
        flexDirection: 'row', alignItems: 'center',
        marginVertical: '3mm'
    },
    fieldTitle: {
        fontSize: 11, fontWeight: 'bold', color: '#444',
        marginStart: '3mm'
    },
    switch: {
    }
});