import mfetch from "./mfetch";

export const API_URL = "https://figaapi.jvjsc.com";

export default class api {

    static getConfigs() {
        return new Promise((resolve) => {
            const _body = { Method: "GET", Data: '' };
            mfetch.do(`${API_URL}/configs`, { method: "POST" , body: JSON.stringify(_body) }).then(rs => rs.text())
            .then(t => { 
                let rs = JSON.parse(t);
                resolve(rs);
            })
            .catch(e => {
                console.log(e);
                resolve(null);
            });
        });
    }

    static updateConfigs(data) {
        return new Promise((resolve) => {
            const _body = { Method: "UPDATE", Data: data };
            mfetch.do(`${API_URL}/configs`, { method: "POST" , body: JSON.stringify(_body) }).then(rs => rs.text())
            .then(t => { 
                let rs = JSON.parse(t);
                resolve(rs);
            })
            .catch(e => {
                console.log(e);
                resolve(null);
            });
        });
    }

    static getUnit(selectedUnit) {
        return new Promise((resolve) => {
            if (!(0 < selectedUnit?.ID)) {
                resolve({ UnitID: "", UnitName: "", AttackRange: 0, TargetRange: 0, Speed: 0 });
            }
            else {
                const _body = { Method: "GET", Data: selectedUnit.ID };
                mfetch.do(`${API_URL}/unit`, { method: "POST" , body: JSON.stringify(_body) }).then(rs => rs.text())
                .then(t => { 
                    let rs = JSON.parse(t);
                    resolve(rs);
                })
                .catch(e => {
                    console.log(e);
                    resolve(null);
                });
            }
        });
    }

    static getAllUnits() {
        return new Promise((resolve) => {
            const _body = { Method: "ALL" };
            mfetch.do(`${API_URL}/unit`, { method: "POST" , body: JSON.stringify(_body) }).then(rs => rs.text())
            .then(t => { 
                let rs = JSON.parse(t);
                resolve(rs);
            })
            .catch(e => {
                console.log(e);
                resolve(null);
            });
        });
    }
    

    static createUnit(newUnit) {

        return new Promise((resolve) => {
            const _body = { Method: "CREATE", Data: newUnit };
            mfetch.do(`${API_URL}/unit`, { method: "POST" , body: JSON.stringify(_body) }).then(rs => rs.text())
            .then(t => { 
                let rs = JSON.parse(t);
                resolve(rs);
            })
            .catch(e => {
                console.log(e);
                resolve(null);
            });
        });
    }

    static updateUnit(unit) {
        return new Promise((resolve) => {
            const _body = unit.UnitName != 'del' ? { Method: "UPDATE", Data: unit }
                                                 : { Method: "DELETE", Data: unit.ID };
            mfetch.do(`${API_URL}/unit`, { method: "POST" , body: JSON.stringify(_body) }).then(rs => rs.text())
            .then(t => { 
                let rs = JSON.parse(t);
                resolve(rs);
            })
            .catch(e => {
                console.log(e);
                resolve(null);
            });
        });
    }
    

    static getAttack(attackID) {
        return new Promise((resolve) => {
            if (attackID == -9999999) {
                resolve({  });
            }
            else if (attackID < 0) {
                const _body = { Method: "GET", Data: attackID * -1 };
                mfetch.do(`${API_URL}/attack`, { method: "POST" , body: JSON.stringify(_body) }).then(rs => rs.text())
                .then(t => { 
                    let rs = JSON.parse(t);
                    if (0 < rs?.Data?.ID) {
                        rs.Data.AttackID += " copy";
                        rs.Data.ID = rs.Data.ID * -1;
                    }
                    resolve(rs);
                })
                .catch(e => {
                    console.log(e);
                    resolve(null);
                });
            }
            else {
                const _body = { Method: "GET", Data: attackID };
                mfetch.do(`${API_URL}/attack`, { method: "POST" , body: JSON.stringify(_body) }).then(rs => rs.text())
                .then(t => { 
                    let rs = JSON.parse(t);
                    resolve(rs);
                })
                .catch(e => {
                    console.log(e);
                    resolve(null);
                });
            }
        });
    }
    static getAllAttacks() {
        return new Promise((resolve) => {
            const _body = { Method: "ALL" };
            mfetch.do(`${API_URL}/attack`, { method: "POST" , body: JSON.stringify(_body) }).then(rs => rs.text())
            .then(t => { 
                let rs = JSON.parse(t);
                resolve(rs);
            })
            .catch(e => {
                console.log(e);
                resolve(null);
            });
        });
    }    
    static getUnitAttacks(selectedUnit) {
        return new Promise((resolve) => {
            if (!(0 < selectedUnit?.ID)) {
                resolve([]);
            }
            else {
                const _body = { Method: "GET_BY_UNITID", Data: selectedUnit.ID };
                mfetch.do(`${API_URL}/attack`, { method: "POST" , body: JSON.stringify(_body) }).then(rs => rs.text())
                .then(t => { 
                    let rs = JSON.parse(t);
                    resolve(rs);
                })
                .catch(e => {
                    console.log(e);
                    resolve(null);
                });
            }
        });
    }
    static createAttack(newAttack) {
        console.log('a', newAttack);
        return new Promise((resolve) => {
            const _body = { Method: "CREATE", Data: newAttack };
            mfetch.do(`${API_URL}/attack`, { method: "POST" , body: JSON.stringify(_body) }).then(rs => rs.text())
            .then(t => { 
                let rs = JSON.parse(t);
                resolve(rs);
            })
            .catch(e => {
                console.log(e);
                resolve(null);
            });
        });
    }
    static updateAttack(attack) {
        return new Promise((resolve) => {
            let _method = attack.AttackID != 'del' ? "UPDATE" : "DELETE";
            const _body = { Method: _method, Data: attack };
            mfetch.do(`${API_URL}/attack`, { method: "POST" , body: JSON.stringify(_body) }).then(rs => rs.text())
            .then(t => { 
                let rs = JSON.parse(t);
                if (_method == "DELETE") {
                    location.reload();
                }
                else {
                    resolve(rs);
                }
            })
            .catch(e => {
                console.log(e);
                resolve(null);
            });
        });
    }
}