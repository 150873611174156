
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';
import api from '../api/api';
import { Actions } from '../contexts/actions';

import * as editors from './UnitIndividualEditors';

export const UnitDetailSelector = (props) => {

    const { dDispatch, selectedUnit, appStyles, reload, setReload, setData } = props;

    let unit = selectedUnit;
    let data = selectedUnit.Data ?? {};

    if (!Object.keys(editors).includes(selectedUnit?.UnitID))
        return null;

    return editors[selectedUnit?.UnitID]({ dDispatch, data, setData, appStyles, reload, setReload });
}