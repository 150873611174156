
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList, Switch } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';
import api from '../api/api';
import { Actions } from '../contexts/actions';
import { MToogle } from './MToggle';
import { MSwitch } from './MSwitch';



export const AttackEditor = (props) => {

    const { attackID, selectedUnit, appStyles, onCloneAttack } = props;
    const [ attack, setAttack ] = useState(null);

    const [ AttackID, setAttackID ] = useState(attack?.AttackID);
    const [ IsLeaderVersion, setIsLeaderVersion ] = useState(attack?.IsLeaderVersion);
    const [ AttackRange, setAttackRange ] = useState(attack?.AttackRange);
    const [ TargetRange, setTargetRange ] = useState(attack?.TargetRange);
    const [ AttackDuration, setAttackDuration ] = useState(attack?.AttackDuration);
    
    const [ Damage, setDamage ] = useState(attack?.Damage);
    const [ AimingPeriod, setAimingPeriod ] = useState(attack?.AimingPeriod);
    const [ ProjectileSpeed, setProjectileSpeed ] = useState(attack?.ProjectileSpeed);
    const [ Animation, setAnimation ] = useState(attack?.Animation);

    const [ Type, setType ] = useState(attack?.Type);
    const [ TargetingTypes, setTargetingTypes ] = useState(attack?.TargetingTypes);
    const [ refreshing, setRefreshing ] = useState(false);

    const getIsDirty = () => {
        if (!(0 < attack?.ID)) { return true; }
        if (AttackID != attack.AttackID) { return true; }
        if (IsLeaderVersion != attack.IsLeaderVersion) { return true; }
        if (AttackRange != attack.AttackRange) { return true; }
        if (TargetRange != attack.TargetRange) { return true; }
        if (AttackDuration != attack.AttackDuration) { return true; }
        
        if (Damage != attack.Damage) { return true; }
        if (AimingPeriod != attack.AimingPeriod) { return true; }
        if (ProjectileSpeed != attack.ProjectileSpeed) { return true; }
        if (Animation != attack.Animation) { return true; }
        
        if (Type != attack.Type) { return true; }
        
        //^ compare TargetingTypes
        let tts = (TargetingTypes ?? '').split('|').filter(p => 0 < p?.length);
        let atts = (attack?.TargetingTypes ?? '').split('|').filter(p => 0 < p?.length);
        if (tts.length != atts.length) {
            return true;
        }
        tts.forEach(tt => {
            if (!attack?.TargetingTypes?.includes(tt)) {
                return true;
            }
        });
        return false;
    }

    const reload = (newID) => {
        setRefreshing(true);
        api.getAttack(newID ?? attackID).then(rs => {
            setRefreshing(false);
            if (rs.OK && rs.Data != null) {
                
                
                console.log('setAttack(rs.Data);', JSON.stringify(rs.Data, null, 4));

                setAttack(rs.Data);


                setAttackID(rs.Data.AttackID)
                setIsLeaderVersion(rs.Data.IsLeaderVersion)
                setAttackRange(rs.Data.AttackRange)
                setTargetRange(rs.Data.TargetRange)
                setAttackDuration(rs.Data.AttackDuration)
                
                setDamage(rs.Data.Damage)
                setAimingPeriod(rs.Data.AimingPeriod)
                setProjectileSpeed(rs.Data.ProjectileSpeed)
                setAnimation(rs.Data.Animation)
                
                setType(rs.Data.Type)
                setTargetingTypes(rs.Data.TargetingTypes)
                setRefreshing(rs.Data.Refreshing)                
            }
        })
    }

    const isDitry = getIsDirty();

    const doSetTargetingTypes = (v) => {
        if ((TargetingTypes ?? '').includes(`${v}|`)) { 
            let newTT = TargetingTypes.replace(`${v}|`, '');
            if (newTT == 'undefined') { newTT = ''; }
            setTargetingTypes(newTT);
        }
        else {
            setTargetingTypes(`${TargetingTypes ?? ''}${v}|`);
        }
    }
    
    const compileWIPObject = () => {
        return {
            UnitID: selectedUnit.ID, AttackID, IsLeaderVersion, AttackRange, TargetRange, AttackDuration, Type, TargetingTypes,
            Damage, AimingPeriod, ProjectileSpeed, Animation
        }
    }

    const saveAttack = () => {
        setRefreshing(true);
        if (attack?.ID > 0 || attackID > 0) {
            let send = { ...attack, ...compileWIPObject() };
            if (!(0 < send.ID)) { send.ID = attackID }
            api.updateAttack(send).then(rs => {
                setRefreshing(false);
                if (rs.OK) {
                    reload();
                }
            })
        }
        else {
            api.createAttack({ ...compileWIPObject() }).then(rs => {
                setRefreshing(false);
                if (rs.OK) {
                    reload(rs.Data.ID);
                }
            });
        }
    }

    useEffect(() => reload(), []);

    return refreshing ? (<Text style={appStyles.fieldTitle} >{"loading..."}</Text>) : (
        
        <View style={[appStyles.form, { borderWidth: IsLeaderVersion || isDitry ? 3 : 1, borderColor: isDitry ? 'red' : undefined, borderRadius: isDitry ? 0 : undefined }]} >

            {/* ID & ForLeader */}
            <View style={[styles.horz, { justifyContent: 'space-between' }]}>
                <View>
                    <Text style={appStyles.fieldTitle} >{`AttackID < ${attackID} >  AS ${attack?.AttackSpeed}  WU ${attack?.WindUp}`}</Text>
                    <TextInput style={[appStyles.fieldLong, { minWidth: '7cm' } ]} value={AttackID ?? ''} onChangeText={setAttackID} ></TextInput>
                </View>
                <View style={styles.space} />
                <View>
                    <Text style={appStyles.fieldTitle} >{"Is For Leader"}</Text>
                    <MToogle value={IsLeaderVersion} onValueChange={() => setIsLeaderVersion(!IsLeaderVersion)} />
                </View>
            </View>
            

            {/* RANGES / SPEED DAMAGE / WIND UP */}
            <View style={styles.horz}>
                <View>
                    <Text style={appStyles.fieldTitle} >{"Attack Range"}</Text>
                    <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={AttackRange} onChangeText={setAttackRange} ></TextInput>
                </View>
                <View style={styles.space} />
                <View>                        
                    <Text style={appStyles.fieldTitle} >{"Target Range"}</Text>
                    <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={TargetRange} onChangeText={setTargetRange} ></TextInput>
                </View>
                <View style={styles.space} />
                <View>
                    <Text style={appStyles.fieldTitle} >{"Attack Duration"}</Text>
                    <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={AttackDuration} onChangeText={setAttackDuration} ></TextInput>
                </View>
                <View style={styles.space} />
                <View>
                    <Text style={appStyles.fieldTitle} >{"Damage"}</Text>
                    <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={Damage} onChangeText={setDamage} ></TextInput>
                </View>
                <View style={styles.space} />
                <View>
                    <Text style={appStyles.fieldTitle} >{"Aiming time"}</Text>
                    <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={AimingPeriod} onChangeText={setAimingPeriod} ></TextInput>
                </View>
            </View>
            
            {/* TYPE */}
            <View style={[styles.horz]}>
                <View>
                    <Text style={appStyles.fieldTitle} >{"Type"}</Text>
                    <View style={{height : '2mm'}} />
                    <MSwitch choseables={[ 'Melee', 'Ranged', 'LeaderAbility' ]} value={Type} onValueChange={setType} />
                </View>
                <View style={styles.space} />
                <View >
                    <Text style={appStyles.fieldTitle} >{"Animation"}</Text>
                    <TextInput textContentType='telephoneNumber' style={[appStyles.field, { marginTop: '3mm' }]} 
                        value={Animation} onChangeText={setAnimation} ></TextInput>
                </View>
                <View style={styles.space} />
                <View >
                    <Text style={appStyles.fieldTitle} >{"Projectile Speed"}</Text>
                    <TextInput textContentType='telephoneNumber' style={[appStyles.field, { marginTop: '3mm' }]} 
                        value={ProjectileSpeed} onChangeText={setProjectileSpeed} ></TextInput>
                </View>
            </View>

            {/* TARGETING TYPES */}
            <Text style={appStyles.fieldTitle} >{"Targeting Type"}</Text>
            <View style={{height : '2mm'}} />        
            <MSwitch multi choseables={[ 'Leader', 'Aerial', 'Ground', 'Construct' ]} value={TargetingTypes} onValueChange={doSetTargetingTypes} />
            <View style={{height : '2mm'}} />

            {/* SAVE */}
            { !isDitry ? null :
                <TouchableOpacity style={appStyles.button} onPress={() => { saveAttack(); }} >
                    <Text style={appStyles.buttonTxt} >{"SAVE"}</Text>
                </TouchableOpacity>
            }
            {/* CLONE */}
            { !(0 < attackID) ? null :
                <TouchableOpacity style={[appStyles.button, { backgroundColor: '#fff', padding: 0, margin: 0, marginBottom: '2mm' }]} 
                    onPress={() => { onCloneAttack(attack); }} >
                    <Text style={[appStyles.buttonTxt, { color: '#009688' }]} >{"make a copy"}</Text>
                </TouchableOpacity>
            }

        </View>

    );

}



const styles = StyleSheet.create({
    comp: {
        padding: '10mm'
    },
    id: { fontSize: 9 },
    name: { fontSize: 14, fontWeight: 'bold' },
    horz: {
        flexDirection: 'row', alignItems: 'stretch'
    },
    space: {
        width: '6mm', height: '6mm'
    },
});