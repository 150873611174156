
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';
import { MToogle } from '../MToggle';

export default function FIG02_WINDPATH(props) {

    const { dDispatch, data, appStyles, setData } = props;

    const [ showingTechnical, setShowingTechnical ] = useState(false);

    const [ AttackDuration, setAttackDuration ] = useState(data?.AttackDuration);
    const [ SummonDuration, setSummonDuration ] = useState(data?.SummonDuration);

    const [ AimingTime, setAimingTime ] = useState(data?.AimingTime);

    const [ TargetRange, setTargetRange ] = useState(data?.TargetRange);
    const [ ProjectileSpeed, setProjectileSpeed ] = useState(data?.ProjectileSpeed);
    
    const [ Damage, setDamage ] = useState(data?.Damage);
    const [ DamageLeader, setDamageLeader ] = useState(data?.DamageLeader);
    const [ AttackRange, setAttackRange ] = useState(data?.AttackRange);
    const [ AttackRangeLeader, setAttackRangeLeader ] = useState(data?.AttackRangeLeader);
    
    const [ LA_Width, setLA_Width ] = useState(data?.LA_Width);
    const [ LA_Length, setLA_Length ] = useState(data?.LA_Length);
    const [ LA_AOEDamage, setLA_AOEDamage ] = useState(data?.LA_AOEDamage);
    const [ LA_PushDistance , setLA_PushDistance ] = useState(data?.LA_PushDistance);
        
    const [ UA_TrailSpeedBoost, setUA_TrailSpeedBoost ] = useState(data?.UA_TrailSpeedBoost);

        
    
    

    useEffect(() => {
        setData(compileWIPObject());
    }
    , 
    [ AimingTime, AttackDuration, SummonDuration,
        TargetRange, ProjectileSpeed,
        Damage, DamageLeader, AttackRange, AttackRangeLeader,
        LA_Width, LA_Length,
        LA_AOEDamage, LA_PushDistance,
        UA_TrailSpeedBoost ]);

    const compileWIPObject = () => {
        return {
            AimingTime, AttackDuration, SummonDuration,
            TargetRange, ProjectileSpeed,
            Damage, DamageLeader, AttackRange, AttackRangeLeader,
            LA_Width, LA_Length,
            LA_AOEDamage, LA_PushDistance,
            UA_TrailSpeedBoost }
    }

    return (        
        <View style={appStyles.form} >
            
            {/* switch */}
            <View style={[ styles.horz, { alignItems: 'center' } ]}>
                <Text style={appStyles.fieldTitleBig} >{`BALANCING`}</Text>
                <View style={styles.space} />
                <MToogle value={showingTechnical} onValueChange={() => setShowingTechnical(!showingTechnical)} />
                <View style={styles.space} />
                <Text style={appStyles.fieldTitleBig} >{`TECHNICAL`}</Text>
            </View>



        { false ? <Text>{"loading..."}</Text> 
        : showingTechnical 
            
            ? //^ TECHNICAL
            <View style={{ paddingBottom: '6mm' }} >                
                <View style={styles.horz}>
                    {/* <View>
                        <Text style={appStyles.fieldTitle} >{`Melee Aiming Time`}</Text>
                        <TextInput style={appStyles.fieldLong} value={MeleeAimingTime} onChangeText={setMeleeAimingTime} ></TextInput>
                    </View>
                    <View style={styles.space} /> */}
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Aiming Time`}</Text>
                        <TextInput style={appStyles.fieldLong} value={AimingTime} onChangeText={setAimingTime} ></TextInput>
                    </View>
                </View>
            </View>
            
            : //^ BALANCING
            <View style={{ paddingBottom: '6mm' }} >

                {/*//^ ATTACK SUMMON DURATION */}
                <View style={styles.horz}>
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{`Attack Duration`}</Text>
                        <TextInput style={appStyles.field} value={AttackDuration} onChangeText={setAttackDuration} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{`Summon Duration`}</Text>
                        <TextInput style={appStyles.field} value={SummonDuration} onChangeText={setSummonDuration} ></TextInput>
                    </View>
                </View>


                {/* //^ TARGET RANGE / PROJECTILE SPEED / AOE RADIUS */}
                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Targeting range`}</Text>
                        <TextInput style={appStyles.field} value={TargetRange} onChangeText={setTargetRange} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Projectile Speed`}</Text>
                        <TextInput style={appStyles.field} value={ProjectileSpeed} onChangeText={setProjectileSpeed} ></TextInput>
                    </View>
                </View>
                
                <View style={styles.space} />

                {/* UNIT LEADER SEPARATION*/}
                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitleBigger} >{"For Unit"}</Text>
                        <View style={styles.spaceSmall} />
                        
                        <Text style={appStyles.fieldTitle} >{`Attack Range`}</Text>
                        <TextInput style={appStyles.fieldLong} value={AttackRange} onChangeText={setAttackRange} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`Damage`}</Text>
                        <TextInput style={appStyles.fieldLong} value={Damage} onChangeText={setDamage} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitleBigger} >{"For Leader"}</Text>
                        <View style={styles.spaceSmall} />
                        
                        <Text style={appStyles.fieldTitle} >{` `}</Text>
                        <TextInput style={appStyles.fieldLong} value={AttackRangeLeader} onChangeText={setAttackRangeLeader} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{` `}</Text>
                        <TextInput style={appStyles.fieldLong} value={DamageLeader} onChangeText={setDamageLeader} ></TextInput>
                    </View>
                </View>
                
                <View style={styles.space} />

                {/* LEADER ABILITY ATTACK */}
                <Text style={appStyles.fieldTitleBigger} >{"Leader Ability Attack"}</Text>
                <View style={styles.space} />

                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitleBig} >{`Range length`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_Length} onChangeText={setLA_Length} ></TextInput>
                        <Text style={appStyles.fieldTitleBig} >{`Range width`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_Width} onChangeText={setLA_Width} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitleBig} >{`AOE Damage`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_AOEDamage} onChangeText={setLA_AOEDamage} ></TextInput>
                        <Text style={appStyles.fieldTitleBig} >{`Push Distance`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_PushDistance} onChangeText={setLA_PushDistance} ></TextInput>
                    </View>
                </View>


                {/* UNIQUE EFFECT */}
                <Text style={appStyles.fieldTitleBigger} >{"Unique Effect"}</Text>
                <View style={styles.space} />

                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitleBig} >{`Trail speed boost:`}</Text>
                        <TextInput style={appStyles.fieldLong} value={UA_TrailSpeedBoost} onChangeText={setUA_TrailSpeedBoost} ></TextInput>
                    </View>
                </View>


            </View>
        
        
        }
        </View>
    );
}


const styles = StyleSheet.create({
    horz: {
        flexDirection: 'row', alignItems: 'stretch'
    },
    space: {
        width: '6mm', height: '6mm'
    },
    spaceSmall: {
        width: '3mm', height: '3mm'
    },
    fieldTitleBig: {
        fontSize: 12, fontWeight: 'bold', color: '#444'
    }
});