
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';
import { MToogle } from '../MToggle';



export default function FIG08_JKBICYCLE(props) {

    const { dDispatch, data, appStyles, setData } = props;

    const [ showingTechnical, setShowingTechnical ] = useState(false);

    const [ AttackDuration, setAttackDuration ] = useState(data?.AttackDuration);
    const [ SummonDuration, setSummonDuration ] = useState(data?.SummonDuration);

    const [ OnBikeSpeed, setOnBikeSpeed ] = useState(data?.OnBikeSpeed);
    const [ TargetRange, setTargetRange ] = useState(data?.TargetRange);
    const [ CrashTargetRange, setCrashTargetRange ] = useState(data?.CrashTargetRange);
    const [ AttackRange, setAttackRange ] = useState(data?.AttackRange);
    const [ CrashAttackRange, setCrashAttackRange ] = useState(data?.CrashAttackRange);
    const [ MeleeDamage, setMeleeDamage ] = useState(data?.MeleeDamage);
    const [ CrashDamage, setCrashDamage ] = useState(data?.CrashDamage);
    const [ MeleeAimingTime, setMeleeAimingTime ] = useState(data?.MeleeAimingTime);

    useEffect(() => {
        setData(compileWIPObject());
    }
    , 
    [ OnBikeSpeed, AttackDuration, SummonDuration,
        TargetRange, CrashTargetRange,
        AttackRange, CrashAttackRange,
        MeleeDamage, CrashDamage,
        MeleeAimingTime ]);

    const compileWIPObject = () => {
        return {
            OnBikeSpeed, AttackDuration, SummonDuration,
            TargetRange, CrashTargetRange,
            AttackRange, CrashAttackRange,
            MeleeDamage, CrashDamage,
            MeleeAimingTime
        }
    }

    return (        
        <View style={appStyles.form} >
            
            {/* switch */}
            <View style={[ styles.horz, { alignItems: 'center' } ]}>
                <Text style={appStyles.fieldTitleBig} >{`BALANCING`}</Text>
                <View style={styles.space} />
                <MToogle value={showingTechnical} onValueChange={() => setShowingTechnical(!showingTechnical)} />
                <View style={styles.space} />
                <Text style={appStyles.fieldTitleBig} >{`TECHNICAL`}</Text>
            </View>



        { false ? <Text>{"loading..."}</Text> 
        : showingTechnical 
            
            ? //^ TECHNICAL
            <View style={{ paddingBottom: '6mm' }} >                
                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Melee Aiming Time`}</Text>
                        <TextInput style={appStyles.fieldLong} value={MeleeAimingTime} onChangeText={setMeleeAimingTime} ></TextInput>
                    </View>
                </View>
            </View>
            
            : //^ BALANCING
            <View style={{ paddingBottom: '6mm' }} >

                {/*//^ ATTACK SUMMON DURATION */}
                <View style={styles.horz}>
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{`On Bike Speed`}</Text>
                        <TextInput style={appStyles.field} value={OnBikeSpeed} onChangeText={setOnBikeSpeed} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{`Attack Duration`}</Text>
                        <TextInput style={appStyles.field} value={AttackDuration} onChangeText={setAttackDuration} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{`Summon Duration`}</Text>
                        <TextInput style={appStyles.field} value={SummonDuration} onChangeText={setSummonDuration} ></TextInput>
                    </View>
                </View>


                {/* UNIT LEADER SEPARATION*/}
                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitleBigger} >{"For Melee"}</Text>
                        <View style={styles.spaceSmall} />
                        
                        <Text style={appStyles.fieldTitle} >{`Target Range`}</Text>
                        <TextInput style={appStyles.fieldLong} value={TargetRange} onChangeText={setTargetRange} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`Attack Range`}</Text>
                        <TextInput style={appStyles.fieldLong} value={AttackRange} onChangeText={setAttackRange} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`Melee Damage`}</Text>
                        <TextInput style={appStyles.fieldLong} value={MeleeDamage} onChangeText={setMeleeDamage} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitleBigger} >{"For Crash"}</Text>
                        <View style={styles.spaceSmall} />
                        
                        <Text style={appStyles.fieldTitle} >{`Crash Target Range`}</Text>
                        <TextInput style={appStyles.fieldLong} value={CrashTargetRange} onChangeText={setCrashTargetRange} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`Crash Attack Range`}</Text>
                        <TextInput style={appStyles.fieldLong} value={CrashAttackRange} onChangeText={setCrashAttackRange} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`Crash Damage`}</Text>
                        <TextInput style={appStyles.fieldLong} value={CrashDamage} onChangeText={setCrashDamage} ></TextInput>
                    </View>
                </View>


            </View>
        
        
        }
        </View>
    );
}


const styles = StyleSheet.create({
    horz: {
        flexDirection: 'row', alignItems: 'stretch'
    },
    space: {
        width: '6mm', height: '6mm'
    },
    spaceSmall: {
        width: '3mm', height: '3mm'
    },
    fieldTitleBig: {
        fontSize: 12, fontWeight: 'bold', color: '#444'
    }
});