
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';
import { MToogle } from '../MToggle';

export default function MCH02_SNIPER(props) {

    const { dDispatch, data, appStyles, setData } = props;

    const [ showingTechnical, setShowingTechnical ] = useState(false);

    const [ RangedAttackDuration, setRangedAttackDuration ] = useState(data?.RangedAttackDuration);
    const [ MeleeAttackDuration, setMeleeAttackDuration ] = useState(data?.MeleeAttackDuration);
    const [ SummonDuration, setSummonDuration ] = useState(data?.SummonDuration);

    const [ AimingTime, setAimingTime ] = useState(data?.AimingTime);
    const [ MeleeAimingTime, setMeleeAimingTime ] = useState(data?.MeleeAimingTime);

    const [ TargetRange, setTargetRange ] = useState(data?.TargetRange);
    const [ ProjectileSpeed, setProjectileSpeed ] = useState(data?.ProjectileSpeed);
    const [ MeleeRange, setMeleeRange ] = useState(data?.MeleeRange);
    

    const [ Damage, setDamage ] = useState(data?.Damage);
    const [ MeleeDamage, setMeleeDamage ] = useState(data?.MeleeDamage);
    const [ AttackRange, setAttackRange ] = useState(data?.AttackRange);
    
    const [ DamageLeader, setDamageLeader ] = useState(data?.DamageLeader);
    const [ MeleeDamageLeader, setMeleeDamageLeader ] = useState(data?.MeleeDamageLeader);
    const [ AttackRangeLeader, setAttackRangeLeader ] = useState(data?.AttackRangeLeader);

    
    const [ LA_BeamWidth, setLA_BeamWidth ] = useState(data?.LA_BeamWidth);
    const [ LA_Ticks, setLA_Ticks ] = useState(data?.LA_Ticks);
    const [ LA_TickDamage, setLA_TickDamage ] = useState(data?.LA_TickDamage);
    const [ LA_TickInterval , setLA_TickInterval ] = useState(data?.LA_TickInterval);
        
    const [ LA_DamageBuffValue, setLA_DamageBuffValue ] = useState(data?.LA_DamageBuffValue);
    const [ LA_DamageBuffDuration, setLA_DamageBuffDuration ] = useState(data?.LA_DamageBuffDuration);

        
    
    

    useEffect(() => {
        setData(compileWIPObject());
    }
    , 
    [ AimingTime, MeleeAimingTime, TargetRange, MeleeRange, MeleeAttackDuration, RangedAttackDuration, SummonDuration,
        MeleeDamage, Damage, AttackRange, ProjectileSpeed,
        MeleeDamageLeader, DamageLeader, AttackRangeLeader,
        LA_BeamWidth, LA_Ticks, LA_TickDamage, LA_TickInterval,
        LA_DamageBuffValue, LA_DamageBuffDuration ]);

    const compileWIPObject = () => {
        return {
            AimingTime, MeleeAimingTime, TargetRange, MeleeRange, MeleeAttackDuration, RangedAttackDuration, SummonDuration,
                MeleeDamage, Damage, AttackRange, ProjectileSpeed,
                MeleeDamageLeader, DamageLeader, AttackRangeLeader,
                LA_BeamWidth, LA_Ticks, LA_TickDamage, LA_TickInterval,
                LA_DamageBuffValue, LA_DamageBuffDuration }
    }

    return (        
        <View style={appStyles.form} >
            
            {/* switch */}
            <View style={[ styles.horz, { alignItems: 'center' } ]}>
                <Text style={appStyles.fieldTitleBig} >{`BALANCING`}</Text>
                <View style={styles.space} />
                <MToogle value={showingTechnical} onValueChange={() => setShowingTechnical(!showingTechnical)} />
                <View style={styles.space} />
                <Text style={appStyles.fieldTitleBig} >{`TECHNICAL`}</Text>
            </View>



        { false ? <Text>{"loading..."}</Text> 
        : showingTechnical 
            
            ? //^ TECHNICAL
            <View style={{ paddingBottom: '6mm' }} >                
                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Melee Aiming Time`}</Text>
                        <TextInput style={appStyles.fieldLong} value={MeleeAimingTime} onChangeText={setMeleeAimingTime} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Aiming Time`}</Text>
                        <TextInput style={appStyles.fieldLong} value={AimingTime} onChangeText={setAimingTime} ></TextInput>
                    </View>
                </View>
            </View>
            
            : //^ BALANCING
            <View style={{ paddingBottom: '6mm' }} >

                {/*//^ ATTACK SUMMON DURATION */}
                <View style={styles.horz}>
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{`Ranged Attack Duration`}</Text>
                        <TextInput style={appStyles.field} value={RangedAttackDuration} onChangeText={setRangedAttackDuration} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{`Melee Attack Duration`}</Text>
                        <TextInput style={appStyles.field} value={MeleeAttackDuration} onChangeText={setMeleeAttackDuration} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{`Summon Duration`}</Text>
                        <TextInput style={appStyles.field} value={SummonDuration} onChangeText={setSummonDuration} ></TextInput>
                    </View>
                </View>

                {/* //^ TARGET RANGE / PROJECTILE SPEED / AOE RADIUS */}
                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Targeting range`}</Text>
                        <TextInput style={appStyles.field} value={TargetRange} onChangeText={setTargetRange} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Projectile Speed`}</Text>
                        <TextInput style={appStyles.field} value={ProjectileSpeed} onChangeText={setProjectileSpeed} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Melee Range`}</Text>
                        <TextInput style={appStyles.field} value={MeleeRange} onChangeText={setMeleeRange} ></TextInput>
                    </View>
                </View>
                
                <View style={styles.space} />

                {/* UNIT LEADER SEPARATION*/}
                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitleBigger} >{"For Unit"}</Text>
                        <View style={styles.spaceSmall} />
                        
                        <Text style={appStyles.fieldTitle} >{`Attack Range`}</Text>
                        <TextInput style={appStyles.fieldLong} value={AttackRange} onChangeText={setAttackRange} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`Damage`}</Text>
                        <TextInput style={appStyles.fieldLong} value={Damage} onChangeText={setDamage} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`Melee Damage`}</Text>
                        <TextInput style={appStyles.fieldLong} value={MeleeDamage} onChangeText={setMeleeDamage} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitleBigger} >{"For Leader"}</Text>
                        <View style={styles.spaceSmall} />
                        
                        <Text style={appStyles.fieldTitle} >{` `}</Text>
                        <TextInput style={appStyles.fieldLong} value={AttackRangeLeader} onChangeText={setAttackRangeLeader} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{` `}</Text>
                        <TextInput style={appStyles.fieldLong} value={DamageLeader} onChangeText={setDamageLeader} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{` `}</Text>
                        <TextInput style={appStyles.fieldLong} value={MeleeDamageLeader} onChangeText={setMeleeDamageLeader} ></TextInput>
                    </View>
                </View>
                
                <View style={styles.space} />

                {/* LEADER ABILITY ATTACK */}
                <Text style={appStyles.fieldTitleBigger} >{"Leader Ability Attack"}</Text>
                <View style={styles.space} />

                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitleBig} >{`Beam width`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_BeamWidth} onChangeText={setLA_BeamWidth} ></TextInput>
                        <Text style={appStyles.fieldTitleBig} >{`Tick Interval`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_TickInterval} onChangeText={setLA_TickInterval} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitleBig} >{`Each Tick Damage`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_TickDamage} onChangeText={setLA_TickDamage} ></TextInput>
                        <Text style={appStyles.fieldTitleBig} >{`Ticks`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_Ticks} onChangeText={setLA_Ticks} ></TextInput>
                    </View>
                </View>


                {/* LEADER ABILITY EFFECT */}
                <Text style={appStyles.fieldTitleBigger} >{"Leader Ability Effect"}</Text>
                <View style={styles.space} />

                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitleBig} >{`All Ranged units gains additional:`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_DamageBuffValue} onChangeText={setLA_DamageBuffValue} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitleBig} >{`% atk for `}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_DamageBuffDuration} onChangeText={setLA_DamageBuffDuration} ></TextInput>
                        <Text style={appStyles.fieldTitleBig} >{`seconds`}</Text>
                    </View>
                </View>


            </View>
        
        
        }
        </View>
    );
}


const styles = StyleSheet.create({
    horz: {
        flexDirection: 'row', alignItems: 'stretch'
    },
    space: {
        width: '6mm', height: '6mm'
    },
    spaceSmall: {
        width: '3mm', height: '3mm'
    },
    fieldTitleBig: {
        fontSize: 12, fontWeight: 'bold', color: '#444'
    }
});