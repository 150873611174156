
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';
import { MToogle } from '../MToggle';

export default function BES07_WOLF3(props) {

    const { dDispatch, data, appStyles, setData } = props;

    const [ showingTechnical, setShowingTechnical ] = useState(false);

    const [ AttackDuration, setAttackDuration ] = useState(data?.AttackDuration);
    const [ SummonDuration, setSummonDuration ] = useState(data?.SummonDuration);

    const [ TargetRange, setTargetRange ] = useState(data?.TargetRange);
    const [ AttackRange, setAttackRange ] = useState(data?.AttackRange);
    const [ Damage, setDamage ] = useState(data?.Damage);
    const [ AimingTime, setAimingTime ] = useState(data?.AimingTime);
    
    

    useEffect(() => {
        setData(compileWIPObject());
    }
    , 
    [ TargetRange, AttackRange, Damage, AimingTime, AttackDuration, SummonDuration, ]);

    const compileWIPObject = () => {
        return { TargetRange, AttackRange, Damage, AimingTime, AttackDuration, SummonDuration, }
    }

    return (        
        <View style={appStyles.form} >
            
            {/* switch */}
            <View style={[ styles.horz, { alignItems: 'center' } ]}>
                <Text style={appStyles.fieldTitleBig} >{`BALANCING`}</Text>
                <View style={styles.space} />
                <MToogle value={showingTechnical} onValueChange={() => setShowingTechnical(!showingTechnical)} />
                <View style={styles.space} />
                <Text style={appStyles.fieldTitleBig} >{`TECHNICAL`}</Text>
            </View>



        { false ? <Text>{"loading..."}</Text> 
        : showingTechnical 
            
            ? //^ TECHNICAL
            <View style={{ paddingBottom: '6mm' }} >                
                <Text style={appStyles.fieldTitle} >{`Aiming Time`}</Text>
                <TextInput style={appStyles.fieldLong} value={AimingTime} onChangeText={setAimingTime} ></TextInput>
            </View>
            
            : //^ BALANCING
            <View style={{ paddingBottom: '6mm' }} >

                {/*//^ ATTACK SUMMON DURATION */}
                <View style={styles.horz}>
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{`Attack Duration`}</Text>
                        <TextInput style={appStyles.field} value={AttackDuration} onChangeText={setAttackDuration} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{`Summon Duration`}</Text>
                        <TextInput style={appStyles.field} value={SummonDuration} onChangeText={setSummonDuration} ></TextInput>
                    </View>
                </View>

                <Text style={appStyles.fieldTitle} >{`Targeting range`}</Text>
                <TextInput style={appStyles.field} value={TargetRange} onChangeText={setTargetRange} ></TextInput>
                <Text style={appStyles.fieldTitle} >{`Attack Range`}</Text>
                <TextInput style={appStyles.field} value={AttackRange} onChangeText={setAttackRange} ></TextInput>
                <Text style={appStyles.fieldTitle} >{`Damage`}</Text>
                <TextInput style={appStyles.field} value={Damage} onChangeText={setDamage} ></TextInput>


            </View>
        
        
        }
        </View>
    );
}


const styles = StyleSheet.create({
    horz: {
        flexDirection: 'row', alignItems: 'stretch'
    },
    space: {
        width: '6mm', height: '6mm'
    },
    spaceSmall: {
        width: '3mm', height: '3mm'
    },
    fieldTitleBig: {
        fontSize: 12, fontWeight: 'bold', color: '#444'
    }
});