
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';
import api from '../api/api';
import { Actions } from '../contexts/actions';
import { AttackEditor } from "./AttackEditor.js";

export const UnitAttacks = (props) => {

    const { dDispatch, selectedUnit, appStyles, reload, hasData } = props;

    const [ refreshing, setRefreshing ] = useState(false);
    const [ attackIDs, setAttackIDs ] = useState([]);

    useEffect(() => {
        setRefreshing(true);
        api.getUnitAttacks(selectedUnit).then(attacks => {
            setRefreshing(false);
            setAttackIDs(attacks?.Data);
        });
    }
    , []);

    const createNewAttack = () => {
        setAttackIDs([ -9999999, ...(attackIDs ?? []) ]);
    }

    // useEffect(() => {
    //     api.getAllAttacks().then(rs => {
    //         console.log('rs', rs.Data.map((p, k) => p.ID));
    //     })
    // }, []);

    const onCloneAttack = attack => {
        setAttackIDs([ -1 * attack.ID, ...(attackIDs ?? []) ]);
    }

    return (
        <View style={[styles.comp, { paddingVertical: '2mm' }]} >
            <View style={styles.horz} >
                <Text style={[appStyles.H1, { textDecorationLine: 'line-through' }]} >{`${selectedUnit?.UnitName}'s Attacks:`}</Text>
                { refreshing ? null :
                    <TouchableOpacity style={[appStyles.button, { margin: 0 }]} onPress={() => createNewAttack()} >
                        <Text style={appStyles.buttonTxt} >{"NEW ATTACK"}</Text>
                    </TouchableOpacity>
                }
            </View>
            { refreshing ? <Text>{"loading..."}</Text> 
            : <View>

                { (attackIDs ?? []).map((p, k) => { return (
                    <AttackEditor key={p} attackID={p} selectedUnit={selectedUnit} appStyles={appStyles} onCloneAttack={onCloneAttack} />
                    ); }) }
              </View>
            }

            { !hasData ? null :
                <TouchableOpacity style={styles.dim} />
            }
        </View>
    );
}


const styles = StyleSheet.create({
    comp: {
        padding: '10mm'
    },
    id: { fontSize: 9 },
    name: { fontSize: 14, fontWeight: 'bold' },
    unitItem: {
        // @ts-ignore
        borderRadius: '2mm',
        borderWidth: 1, borderColor: 'gray', 
        marginVertical: '2mm',
        padding: '10pt', paddingTop: '1pt'
    },
    unitItemSelected: {
        backgroundColor: 'red'
    },
    horz: {
        flexDirection: 'row', justifyContent: 'space-between'
    },
    dim: {
        width: '100%', height: '100%', position: 'absolute', backgroundColor: '#ffffff88'
    }
});