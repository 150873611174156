
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';
import api from '../api/api';
import { Actions } from '../contexts/actions';

export const ConfigsEditor = (props) => {

    const { dDispatch, appStyles } = props;

    const [ refreshing, setRefreshing ] = useState(false);

    const [ SummoningDelay, setSummoningDelay ] = useState("0");
    const [ PregameLength, setPregameLength ] = useState("0");
    const [ Phase1Length, setPhase1Length ] = useState("0");
    const [ Phase2Length, setPhase2Length ] = useState("0");
    const [ Phase3Length, setPhase3Length ] = useState("0");
    const [ StartingEnergy, setStartingEnergy ] = useState("0");
    const [ MaxEnergy, setMaxEnergy ] = useState("0");

    const [ EnergyRechargingRatePhase1, setEnergyRechargingRatePhase1 ] = useState("0");
    const [ EnergyRechargingRatePhase2, setEnergyRechargingRatePhase2 ] = useState("0");
    const [ EnergyRechargingRatePhase3, setEnergyRechargingRatePhase3 ] = useState("0");
    const [ LeaderEnergyRechargingRate, setLeaderEnergyRechargingRate ] = useState("0");

    const reload = (newID) => {
        setRefreshing(true);
        api.getConfigs().then(rs => {
            setRefreshing(false);
            if (rs.OK && rs.Data != null) {                            
                console.log('Configs(rs.Data);', JSON.stringify(rs.Data, null, 4));
                setSummoningDelay(rs.Data.SummoningDelay);
                setPregameLength(rs.Data.PregameLength);
                setPhase1Length(rs.Data.Phase1Length);
                setPhase2Length(rs.Data.Phase2Length);
                setPhase3Length(rs.Data.Phase3Length);
                setStartingEnergy(rs.Data.StartingEnergy);
                setMaxEnergy(rs.Data.MaxEnergy);             
                setEnergyRechargingRatePhase1(rs.Data.EnergyRechargingRatePhase1);
                setEnergyRechargingRatePhase2(rs.Data.EnergyRechargingRatePhase2);
                setEnergyRechargingRatePhase3(rs.Data.EnergyRechargingRatePhase3);
                setLeaderEnergyRechargingRate(rs.Data.LeaderEnergyRechargingRate);
            }
        })
    }
    useEffect(() => reload(), []);


    const compileWIPObject = () => {
        return {
            SummoningDelay, PregameLength, Phase1Length, Phase2Length, Phase3Length, StartingEnergy, MaxEnergy,
            EnergyRechargingRatePhase1, EnergyRechargingRatePhase2, EnergyRechargingRatePhase3, LeaderEnergyRechargingRate
        }
    }
    const saveConfigs = () => {
        setRefreshing(true);
        let send = { ...compileWIPObject() };
        api.updateConfigs(send).then(rs => {
            setRefreshing(false);
            if (rs.OK) {
                reload();
            }
        })
    }

    return (
        <View style={appStyles.form} >
            { refreshing ? <Text>{"loading..."}</Text> 
            : <View>
                
                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitle} >{"Summoning Delay"}</Text>
                        <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={SummoningDelay} onChangeText={setSummoningDelay} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitle} >{"Leader Energy Recharging Rate"}</Text>
                        <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={LeaderEnergyRechargingRate} onChangeText={setLeaderEnergyRechargingRate} ></TextInput>
                    </View>
                    <View style={styles.space} />
                </View>


                <View style={styles.horz}>
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{"Pregame Length"}</Text>
                        <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={PregameLength} onChangeText={setPregameLength} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitle} >{"Phase 1 Length"}</Text>
                        <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={Phase1Length} onChangeText={setPhase1Length} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitle} >{"Phase 2 Length"}</Text>
                        <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={Phase2Length} onChangeText={setPhase2Length} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitle} >{"Phase 3 Length"}</Text>
                        <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={Phase3Length} onChangeText={setPhase3Length} ></TextInput>
                    </View>
                    <View style={styles.space} />
                </View>


                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitle} >{"Starting Energy"}</Text>
                        <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={StartingEnergy} onChangeText={setStartingEnergy} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitle} >{"Max Energy"}</Text>
                        <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={MaxEnergy} onChangeText={setMaxEnergy} ></TextInput>
                    </View>                    
                </View>


                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitle} >{"Energy Recharging Rate Phase 1"}</Text>
                        <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={EnergyRechargingRatePhase1} onChangeText={setEnergyRechargingRatePhase1} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitle} >{"Energy Recharging Rate Phase 2"}</Text>
                        <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={EnergyRechargingRatePhase2} onChangeText={setEnergyRechargingRatePhase2} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitle} >{"Energy Recharging Rate Phase 3"}</Text>
                        <TextInput textContentType='telephoneNumber' style={appStyles.fieldShort} value={EnergyRechargingRatePhase3} onChangeText={setEnergyRechargingRatePhase3} ></TextInput>
                    </View>
                </View>



                <TouchableOpacity style={appStyles.button} onPress={() => { saveConfigs(); }} >
                    <Text style={appStyles.buttonTxt} >{"SAVE"}</Text>
                </TouchableOpacity>
            </View>
            }
        </View>
    );
}


const styles = StyleSheet.create({
    horz: {
        flexDirection: 'row', alignItems: 'stretch'
    },
    space: {
        width: '6mm', height: '6mm'
    },
    fieldTitleBig: {
        fontSize: 12, fontWeight: 'bold', color: '#444'
    }
});