
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';
import { MToogle } from '../MToggle';

export default function MCH03_MISSLE(props) {

    const { dDispatch, data, appStyles, setData } = props;

    const [ showingTechnical, setShowingTechnical ] = useState(false);

    const [ AttackDuration, setAttackDuration ] = useState(data?.AttackDuration);
    const [ SummonDuration, setSummonDuration ] = useState(data?.SummonDuration);

    const [ Damage, setDamage ] = useState(data?.Damage);
    const [ AttackRange, setAttackRange ] = useState(data?.AttackRange);
    const [ TargetRange, setTargetRange ] = useState(data?.TargetRange);
    const [ AimingTime, setAimingTime ] = useState(data?.AimingTime);
    const [ ProjectileSpeed, setProjectileSpeed ] = useState(data?.ProjectileSpeed);
    const [ DamageLeader, setDamageLeader ] = useState(data?.DamageLeader);
    const [ AttackRangeLeader, setAttackRangeLeader ] = useState(data?.AttackRangeLeader);
    const [ AOERadius, setAOERadius ] = useState(data?.AOERadius);
    const [ LA_AOERadius, setLA_AOERadius ] = useState(data?.LA_AOERadius);
    const [ LA_AOEDamage, setLA_AOEDamage ] = useState(data?.LA_AOEDamage);
    const [ LA_AimingTime, setLA_AimingTime ] = useState(data?.LA_AimingTime);
    const [ LA_ProjectileSpeed, setLA_ProjectileSpeed ] = useState(data?.LA_ProjectileSpeed);
    const [ LA_CostBuffCondition, setLA_CostBuffCondition ] = useState(data?.LA_CostBuffCondition);
    const [ LA_CostBuffValue, setLA_CostBuffValue ] = useState(data?.LA_CostBuffValue);
    const [ LA_CostBuffDuration, setLA_CostBuffDuration ] = useState(data?.LA_CostBuffDuration);

        
    
    

    useEffect(() => {
        setData(compileWIPObject());
    }
    , 
    [ Damage, AttackRange, TargetRange, AimingTime, ProjectileSpeed, DamageLeader, AttackRangeLeader, AOERadius, AttackDuration, SummonDuration
        , LA_AOERadius, LA_AOEDamage, LA_AimingTime, LA_ProjectileSpeed, LA_CostBuffCondition, LA_CostBuffValue, LA_CostBuffDuration ]);

    const compileWIPObject = () => {
        return {
            Damage, AttackRange, TargetRange, AimingTime, ProjectileSpeed, DamageLeader, AttackRangeLeader, AOERadius, AttackDuration, SummonDuration
            , LA_AOERadius, LA_AOEDamage, LA_AimingTime, LA_ProjectileSpeed, LA_CostBuffCondition, LA_CostBuffValue, LA_CostBuffDuration }
    }

    return (        
        <View style={appStyles.form} >
            
            {/* switch */}
            <View style={[ styles.horz, { alignItems: 'center' } ]}>
                <Text style={appStyles.fieldTitleBig} >{`BALANCING`}</Text>
                <View style={styles.space} />
                <MToogle value={showingTechnical} onValueChange={() => setShowingTechnical(!showingTechnical)} />
                <View style={styles.space} />
                <Text style={appStyles.fieldTitleBig} >{`TECHNICAL`}</Text>
            </View>



        { false ? <Text>{"loading..."}</Text> 
        : showingTechnical 
            
            ? //^ TECHNICAL
            <View style={{ paddingBottom: '6mm' }} >                
                <View style={styles.horz}>
                    {/* <View>
                        <Text style={appStyles.fieldTitle} >{`Slash Duration 1`}</Text>
                        <TextInput style={appStyles.fieldLong} value={SlashDuration1} onChangeText={setSlashDuration1} ></TextInput>
                    </View>
                    <View style={styles.space} /> */}
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Aiming Time`}</Text>
                        <TextInput style={appStyles.fieldLong} value={AimingTime} onChangeText={setAimingTime} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`Leader Ability Aiming Time`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_AimingTime} onChangeText={setLA_AimingTime} ></TextInput>
                    </View>
                </View>
            </View>
            
            : //^ BALANCING
            <View style={{ paddingBottom: '6mm' }} >

                {/*//^ ATTACK SUMMON DURATION */}
                <View style={styles.horz}>
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{`Attack Duration`}</Text>
                        <TextInput style={appStyles.field} value={AttackDuration} onChangeText={setAttackDuration} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{`Summon Duration`}</Text>
                        <TextInput style={appStyles.field} value={SummonDuration} onChangeText={setSummonDuration} ></TextInput>
                    </View>
                </View>


                {/* //^ TARGET RANGE / PROJECTILE SPEED / AOE RADIUS */}
                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Targeting range`}</Text>
                        <TextInput style={appStyles.field} value={TargetRange} onChangeText={setTargetRange} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Projectile Speed`}</Text>
                        <TextInput style={appStyles.field} value={ProjectileSpeed} onChangeText={setProjectileSpeed} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitle} >{`AOE Radius`}</Text>
                        <TextInput style={appStyles.field} value={AOERadius} onChangeText={setAOERadius} ></TextInput>
                    </View>
                </View>
                
                <View style={styles.space} />

                {/* UNIT LEADER SEPARATION*/}
                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitleBigger} >{"For Unit"}</Text>
                        <View style={styles.spaceSmall} />
                        
                        <Text style={appStyles.fieldTitle} >{`Attack Range`}</Text>
                        <TextInput style={appStyles.fieldLong} value={AttackRange} onChangeText={setAttackRange} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`Damage`}</Text>
                        <TextInput style={appStyles.fieldLong} value={Damage} onChangeText={setDamage} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitleBigger} >{"For Leader"}</Text>
                        <View style={styles.spaceSmall} />
                        
                        <Text style={appStyles.fieldTitle} >{` `}</Text>
                        <TextInput style={appStyles.fieldLong} value={AttackRangeLeader} onChangeText={setAttackRangeLeader} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{` `}</Text>
                        <TextInput style={appStyles.fieldLong} value={DamageLeader} onChangeText={setDamageLeader} ></TextInput>
                    </View>
                </View>
                
                <View style={styles.space} />

                {/* LEADER ABILITY ATTACK */}
                <Text style={appStyles.fieldTitleBigger} >{"Leader Ability Attack"}</Text>
                <View style={styles.space} />

                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitleBig} >{`AOE Radius`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_AOERadius} onChangeText={setLA_AOERadius} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitleBig} >{`AOE Damage`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_AOEDamage} onChangeText={setLA_AOEDamage} ></TextInput>
                        <Text style={appStyles.fieldTitleBig} >{`AOE ProjectileSpeed`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_ProjectileSpeed} onChangeText={setLA_ProjectileSpeed} ></TextInput>
                    </View>
                </View>


                {/* LEADER ABILITY EFFECT */}
                <Text style={appStyles.fieldTitleBigger} >{"Leader Ability Effect"}</Text>
                <View style={styles.space} />

                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitleBig} >{`Mecha units that are above:`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_CostBuffCondition} onChangeText={setLA_CostBuffCondition} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitleBig} >{`...cost gain:`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_CostBuffValue} onChangeText={setLA_CostBuffValue} ></TextInput>
                        <Text style={appStyles.fieldTitleBig} >{`% ATK for:`}</Text>
                        <TextInput style={appStyles.fieldLong} value={LA_CostBuffDuration} onChangeText={setLA_CostBuffDuration} ></TextInput>
                        <Text style={appStyles.fieldTitleBig} >{`seconds`}</Text>
                    </View>
                </View>


            </View>
        
        
        }
        </View>
    );
}


const styles = StyleSheet.create({
    horz: {
        flexDirection: 'row', alignItems: 'stretch'
    },
    space: {
        width: '6mm', height: '6mm'
    },
    spaceSmall: {
        width: '3mm', height: '3mm'
    },
    fieldTitleBig: {
        fontSize: 12, fontWeight: 'bold', color: '#444'
    }
});