import fetch from 'node-fetch';
import { API_URL } from './api';

export default class mfetch {
    /**
     * @param {RequestInfo} url 
     * @param {RequestInit} [init] 
     * @param {string|number} [trace] 
     * @param {boolean} [dontLog] 
     * @param {number} [timeToTimeout] 
     * @param {string} [extra] 
     * @returns {Promise<Response>}
     */
    static do (url, init, trace, dontLog, timeToTimeout, extra) {

        // console.log("url", url);
        
        let logUrl = url; let logUrl2 = '';
        if (logUrl.toString().includes(API_URL)) { 
            logUrl = '_bot_'; 
            logUrl2 = url.toString().replace(API_URL, ''); 
        }
        else { logUrl = ''; logUrl2 = url.toString(); }
        
        let logStr = `FETCH: ${trace ?? ''} ${logUrl}`;
        let logStr2 = ` ${logUrl2}`;
        let logStr3 = `>${0 < extra?.length ? `${extra}` : ''}<`;

        if (init?.method === 'POST') { 
            // console.log('init?.body', init?.body?.length);
            let _init = {
                ...init
                , body: init?.body
            }
            // console.log('init', JSON.stringify(init, null, 4)); 
        }

        // @ts-ignore
        return fetch(url, init);
    }
}