
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';
import api from '../api/api';
import { Actions } from '../contexts/actions';

export const UnitList = (props) => {

    const { dDispatch, unitList, selectedUnit, appStyles, reload } = props;

    const [ refreshing, setRefreshing ] = useState(false);
    const [ createNew, setCreateNew ] = useState(0);

    useEffect(() => {
        setRefreshing(true);
        api.getAllUnits().then(units => {
            setRefreshing(false);
            dDispatch(Actions.setUnitList(units?.Data ?? []));
        });
    }
    , [reload]);

    useEffect(() => {
        if (createNew > 0) {
            dDispatch(Actions.setSelectedUnit({}));
        }
    }
    , [createNew]);

    const createNewUnit = () => {
        dDispatch(Actions.setSelectedUnit(null));
        setCreateNew(createNew + 1);
    }
    const selectUnit = unit => {
        localStorage.setItem("last_selected_unit", unit?.ID);
        dDispatch(Actions.setSelectedUnit(null));
        setTimeout(() => {
            console.log('setSelectedUnit', unit);
            dDispatch(Actions.setSelectedUnit(unit));            
        }, 1);
    };
    if (__DEV__) {
        useEffect(() => {
            let _id = localStorage.getItem("last_selected_unit");
            let finds = unitList?.filter(p => p.ID == _id);            
            if (finds?.length > 0) {
                selectUnit(finds[0]);
            }
        }, [unitList]);
    }

    return (
        <View style={styles.comp} >
            { refreshing ? <Text>{"loading..."}</Text> 
            : <View>
                <TouchableOpacity style={appStyles.button} onPress={() => createNewUnit()} >
                    <Text style={appStyles.buttonTxt} >{"CREATE NEW UNIT"}</Text>
                </TouchableOpacity>

                <Text style={appStyles.fieldTitle} >{"Unit list"}</Text>

                { (unitList ?? []).map((p, k) => { return (
                    <TouchableOpacity key={p.ID} onPress={() => selectUnit(p)}
                        style={[ styles.unitItem, (p.ID == selectedUnit?.ID) ? styles.unitItemSelected : undefined ]} >
                        <Text style={styles.id} >{p.UnitID}</Text>
                        <Text style={styles.name} >{p.UnitName}</Text>
                    </TouchableOpacity>
                    ); }) }
              </View>
            }
        </View>
    );
}


const styles = StyleSheet.create({
    comp: {
        paddingHorizontal: '10mm'
    },
    id: { fontSize: 9 },
    name: { fontSize: 14, fontWeight: 'bold' },
    unitItem: {
        // @ts-ignore
        borderRadius: '2mm',
        borderWidth: 1, borderColor: 'gray', 
        marginVertical: '1mm',
        padding: '10pt', paddingTop: '1pt', paddingBottom: '2pt'
    },
    unitItemSelected: {
        backgroundColor: '#ddd',
        borderWidth: 0
    },
});