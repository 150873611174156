
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';
import api from '../api/api';
import { Actions } from '../contexts/actions';
import { UnitDetailSelector } from './UnitDetailSelector';
import { MSwitch } from './MSwitch';

export const UnitEditor = (props) => {

    const { dDispatch, selectedUnit, appStyles, reload, setReload } = props;

    const [ refreshing, setRefreshing ] = useState(false);
    const [ save, setSave ] = useState(0);

    const [ UnitID, setUnitID ] = useState(selectedUnit?.UnitID);
    const [ UnitName, setUnitName ] = useState(selectedUnit?.UnitName);
    
    // const [ AttackRange, setAttackRange ] = useState(selectedUnit?.AttackRange);
    // const [ TargetRange, setTargetRange ] = useState(selectedUnit?.TargetRange);
    const [ Speed, setSpeed ] = useState(selectedUnit?.Speed);
    const [ Cost, setCost ] = useState(selectedUnit?.Cost);
    const [ Size, setSize ] = useState(selectedUnit?.Size);
    const [ LeaderSize, setLeaderSize ] = useState(selectedUnit?.LeaderSize);
    const [ HP, setHP ] = useState(selectedUnit?.HP);
    const [ LeaderHP, setLeaderHP ] = useState(selectedUnit?.LeaderHP);
    const [ Data, setData ] = useState(selectedUnit?.Data);

    const [ SelfProperties, setSelfProperties ] = useState(selectedUnit?.SelfProperties);
    const [ TargetProperties, setTargetProperties ] = useState(selectedUnit?.TargetProperties);
    const [ LeaderAbilityEnergyCost, setLeaderAbilityEnergyCost ] = useState(selectedUnit?.LeaderAbilityEnergyCost);

    useEffect(() => {
        if (save > 0) {
            setRefreshing(true);
            dDispatch(Actions.setSelectedUnit({}));
            console.log(`${selectedUnit?.ID} > 0 : ${selectedUnit?.ID > 0}`);
            if (selectedUnit?.ID > 0) {
                api.updateUnit({ ...selectedUnit, UnitID, UnitName, ...compileWIPObject() }).then(rs => {
                    setRefreshing(false);
                    if (rs.OK) {
                        dDispatch(Actions.setSelectedUnit(null));
                        setReload(reload + 1);
                    }
                })
            }
            else {
                api.createUnit({ UnitID, UnitName, ...compileWIPObject() }).then(rs => {
                    setRefreshing(false);
                    if (rs.OK) {
                        dDispatch(Actions.setSelectedUnit(null));
                        setReload(reload + 1);
                    }
                });
            }
        }
    }
    , [save]);


    const makeACopy = () => {
        let n = { ...selectedUnit, UnitID: UnitID + " copy", UnitName: UnitName + " copy", ...compileWIPObject() };
        api.createUnit(n)
        .then(rs => {
            setRefreshing(false);
            if (rs.OK) {
                dDispatch(Actions.setSelectedUnit(null));
                setReload(reload + 1);
            }
        });
    };

    const compileWIPObject = () => {
        return {
            // AttackRange, TargetRange, 
            Speed, Cost, Size, LeaderSize, HP, LeaderHP, Data, SelfProperties, TargetProperties, LeaderAbilityEnergyCost
        }
    }

    const doSetSelfProperties = (v) => {
        if ((SelfProperties ?? '').includes(`${v}|`)) { 
            let newTT = SelfProperties.replace(`${v}|`, '');
            if (newTT == 'undefined') { newTT = ''; }
            setSelfProperties(newTT);
        }
        else {
            setSelfProperties(`${SelfProperties ?? ''}${v}|`);
        }
    }
    const doSetTargetProperties = (v) => {
        if ((TargetProperties ?? '').includes(`${v}|`)) { 
            let newTT = TargetProperties.replace(`${v}|`, '');
            if (newTT == 'undefined') { newTT = ''; }
            setTargetProperties(newTT);
        }
        else {
            setTargetProperties(`${TargetProperties ?? ''}${v}|`);
        }
    }

    return (
        <View style={appStyles.form} >
            { refreshing ? <Text>{"loading..."}</Text> 
            : <View>

                <View style={styles.horz}>

                    {/* BASIC */}
                    <View>
                        <Text style={appStyles.fieldTitle} >{`UnitID < ${selectedUnit?.ID} >`}</Text>
                        <TextInput style={appStyles.fieldLong} value={UnitID ?? ''} onChangeText={setUnitID} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{"Unit Name"}</Text>
                        <TextInput style={appStyles.fieldLong} value={UnitName ?? ''} onChangeText={setUnitName} ></TextInput>


                        {/* speed cost */}
                        <View style={styles.horz}>
                            <View>
                                <Text style={appStyles.fieldTitle} >{"Movement Speed"}</Text>
                                <TextInput textContentType='telephoneNumber' style={appStyles.field} value={Speed} onChangeText={setSpeed} ></TextInput>
                            </View>
                            <View style={styles.space} />
                            <View>
                                <Text style={appStyles.fieldTitle} >{"Cost"}</Text>
                                <TextInput textContentType='telephoneNumber' style={appStyles.field} value={Cost} onChangeText={setCost} ></TextInput>
                            </View>
                        </View>

                        
                        {/* unit leader separation */}
                        <View style={styles.horz}>
                            <View>
                                <Text style={appStyles.fieldTitleBig} >{"For Unit"}</Text>
                                <Text style={appStyles.fieldTitle} >{"HP"}</Text>
                                <TextInput textContentType='telephoneNumber' style={appStyles.field} value={HP} onChangeText={setHP} ></TextInput>
                                <Text style={appStyles.fieldTitle} >{"Size"}</Text>
                                <TextInput textContentType='telephoneNumber' style={appStyles.field} value={Size} onChangeText={setSize} ></TextInput>
                            </View>
                            <View style={styles.space} />
                            <View>
                                <Text style={appStyles.fieldTitleBig} >{"For Leader"}</Text>
                                <Text style={appStyles.fieldTitle} >{"Leader HP"}</Text>
                                <TextInput textContentType='telephoneNumber' style={appStyles.field} value={LeaderHP} onChangeText={setLeaderHP} ></TextInput>
                                <Text style={appStyles.fieldTitle} >{"Leader Size"}</Text>
                                <TextInput textContentType='telephoneNumber' style={appStyles.field} value={LeaderSize} onChangeText={setLeaderSize} ></TextInput>
                                <Text style={appStyles.fieldTitle} >{"Leader Ability Energy Cost"}</Text>
                                <TextInput textContentType='telephoneNumber' style={appStyles.field} value={LeaderAbilityEnergyCost} onChangeText={setLeaderAbilityEnergyCost} ></TextInput>
                            </View>
                        </View>

                        
                        <Text style={[appStyles.fieldTitleBig, { marginTop: '2mm', marginBottom: '2mm' }]} >{"Self properties"}</Text>
                        <MSwitch multi choseables={[ 'Leader', 'Aerial', 'Ground', 'Construct' ]} value={SelfProperties} onValueChange={doSetSelfProperties} />
                        <View style={{ height: '1mm' }} />
                        <MSwitch multi choseables={[ 'Ranged', 'Melee' ]} value={SelfProperties} onValueChange={doSetSelfProperties} />
                        <View style={{ height: '3mm' }} />
                        <Text style={[appStyles.fieldTitleBig, { marginBottom: '2mm' }]} >{"Target properties"}</Text>
                        <MSwitch multi choseables={[ 'Leader', 'Aerial', 'Ground', 'Construct' ]} value={TargetProperties} onValueChange={doSetTargetProperties} />
                        <View style={styles.space} />


                        {/* SAVING */}
                        <TouchableOpacity style={appStyles.button} onPress={() => { setSave(save + 1); }} >
                            <Text style={appStyles.buttonTxt} >{"SAVE"}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={appStyles.button} onPress={() => makeACopy()} >
                            <Text style={appStyles.buttonTxt} >{"MAKE A COPY"}</Text>
                        </TouchableOpacity>
                    </View>

                    <View style={styles.space} />
                    <View style={styles.space} />


                    {/* DETAIL */}
                    <UnitDetailSelector dDispatch={dDispatch} selectedUnit={selectedUnit} setData={setData} appStyles={appStyles} reload={reload} setReload={setReload} />



                </View>


            </View>
            }
        </View>
    );
}


const styles = StyleSheet.create({
    horz: {
        flexDirection: 'row', alignItems: 'stretch'
    },
    space: {
        width: '6mm', height: '6mm'
    },
    fieldTitleBig: {
        fontSize: 12, fontWeight: 'bold', color: '#444'
    }
});