
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';
import { MToogle } from '../MToggle';



export default function MCH01_BLADE(props) {

    const { dDispatch, data, appStyles, setData } = props;

    const [ showingTechnical, setShowingTechnical ] = useState(false);

    const [ SummonDuration, setSummonDuration ] = useState(data?.SummonDuration);

    const [ SlashDuration1, setSlashDuration1 ] = useState(data?.SlashDuration1);
    const [ SlashDuration2, setSlashDuration2 ] = useState(data?.SlashDuration2);
    const [ SlashDuration3, setSlashDuration3 ] = useState(data?.SlashDuration3);

    const [ TargetRange, setTargetRange ] = useState(data?.TargetRange);

    const [ AttackRange, setAttackRange ] = useState(data?.AttackRange);
    const [ AttackRangeLeader, setAttackRangeLeader ] = useState(data?.AttackRangeLeader);
    
    const [ SlashDamage1, setSlashDamage1 ] = useState(data?.SlashDamage1);
    // const [ SlashDuration1, setSlashDuration1 ] = useState(data?.SlashDuration1);
    const [ SlashAimingTime1, setSlashAimingTime1 ] = useState(data?.SlashAimingTime1);

    const [ SlashDamage2, setSlashDamage2 ] = useState(data?.SlashDamage2);    
    // const [ SlashDuration2, setSlashDuration2 ] = useState(data?.SlashDuration2);
    const [ SlashAimingTime2, setSlashAimingTime2 ] = useState(data?.SlashAimingTime2);
    
    const [ AOEDamage, setAOEDamage ] = useState(data?.AOEDamage);
    // const [ SlashDurationAOE, setSlashDurationAOE ] = useState(data?.SlashDurationAOE);
    const [ SlashAimingTimeAOE, setSlashAimingTimeAOE ] = useState(data?.SlashAimingTimeAOE);
    const [ AOERadius, setAOERadius ] = useState(data?.AOERadius);

    const [ SlashDamage1Leader, setSlashDamage1Leader ] = useState(data?.SlashDamage1Leader);
    const [ SlashDamage2Leader, setSlashDamage2Leader ] = useState(data?.SlashDamage2Leader);
    const [ AOEDamageLeader, setAOEDamageLeader ] = useState(data?.AOEDamageLeader);
    const [ AOERadiusLeader, setAOERadiusLeader ] = useState(data?.AOERadiusLeader);

    const [ MovementBuffValue, setMovementBuffValue ] = useState(data?.MovementBuffValue);
    const [ MovementBuffDuration, setMovementBuffDuration ] = useState(data?.MovementBuffDuration);

    useEffect(() => {
        setData(compileWIPObject());
    }
    , 
    [ TargetRange, SummonDuration, SlashDuration1, SlashDuration2, SlashDuration3,
        AttackRange, AttackRangeLeader,
        SlashDamage1, SlashAimingTime1, // SlashDuration1, 
        SlashDamage2, SlashAimingTime2, // SlashDuration2, 
        AOEDamage, SlashAimingTimeAOE, // SlashDurationAOE, 
        AOERadius,
        SlashDamage1Leader, SlashDamage2Leader, AOEDamageLeader, AOERadiusLeader,
        MovementBuffValue, MovementBuffDuration ]);

    const compileWIPObject = () => {
        return {
            TargetRange, SummonDuration, SlashDuration1, SlashDuration2, SlashDuration3,
            AttackRange, AttackRangeLeader,
            SlashDamage1, SlashAimingTime1, // SlashDuration1, 
            SlashDamage2, SlashAimingTime2, // SlashDuration2, 
            AOEDamage, SlashAimingTimeAOE, // SlashDurationAOE, 
            AOERadius,
            SlashDamage1Leader, SlashDamage2Leader, AOEDamageLeader, AOERadiusLeader,
            MovementBuffValue, MovementBuffDuration
        }
    }

    return (        
        <View style={appStyles.form} >
            
            {/* switch */}
            <View style={[ styles.horz, { alignItems: 'center' } ]}>
                <Text style={appStyles.fieldTitleBig} >{`BALANCING`}</Text>
                <View style={styles.space} />
                <MToogle value={showingTechnical} onValueChange={() => setShowingTechnical(!showingTechnical)} />
                <View style={styles.space} />
                <Text style={appStyles.fieldTitleBig} >{`TECHNICAL`}</Text>
            </View>



        { false ? <Text>{"loading..."}</Text> 
        : showingTechnical 
            
            ? //^ TECHNICAL
            <View style={{ paddingBottom: '6mm' }} >                
                <View style={styles.horz}>
                    {/* <View>
                        <Text style={appStyles.fieldTitle} >{`Slash Duration 1`}</Text>
                        <TextInput style={appStyles.fieldLong} value={SlashDuration1} onChangeText={setSlashDuration1} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`Slash Duration 2`}</Text>
                        <TextInput style={appStyles.fieldLong} value={SlashDuration2} onChangeText={setSlashDuration2} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`Slash Duration AOE`}</Text>
                        <TextInput style={appStyles.fieldLong} value={SlashDurationAOE} onChangeText={setSlashDurationAOE} ></TextInput>
                    </View>
                    <View style={styles.space} /> */}
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Slash Aiming Time 1`}</Text>
                        <TextInput style={appStyles.fieldLong} value={SlashAimingTime1} onChangeText={setSlashAimingTime1} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`Slash Aiming Time 2`}</Text>
                        <TextInput style={appStyles.fieldLong} value={SlashAimingTime2} onChangeText={setSlashAimingTime2} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`Slash Aiming Time AOE`}</Text>
                        <TextInput style={appStyles.fieldLong} value={SlashAimingTimeAOE} onChangeText={setSlashAimingTimeAOE} ></TextInput>
                    </View>
                </View>
            </View>
            
            : //^ BALANCING
            <View style={{ paddingBottom: '6mm' }} >

                {/*//^ LINE 1 */}
                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Targeting range`}</Text>
                        <TextInput style={appStyles.field} value={TargetRange} onChangeText={setTargetRange} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{`Summon Duration`}</Text>
                        <TextInput style={appStyles.field} value={SummonDuration} onChangeText={setSummonDuration} ></TextInput>
                    </View>
                </View>

                {/*//^ LINE 2 */}
                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Slash 1 Duration`}</Text>
                        <TextInput style={appStyles.field} value={SlashDuration1} onChangeText={setSlashDuration1} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitle} >{`Slash 2 Duration`}</Text>
                        <TextInput style={appStyles.field} value={SlashDuration2} onChangeText={setSlashDuration2} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>                        
                        <Text style={appStyles.fieldTitle} >{`Slash 3 Duration`}</Text>
                        <TextInput style={appStyles.field} value={SlashDuration3} onChangeText={setSlashDuration3} ></TextInput>
                    </View>
                </View>


                {/* UNIT LEADER SEPARATION*/}
                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitleBigger} >{"For Unit"}</Text>
                        <View style={styles.spaceSmall} />
                        
                        <Text style={appStyles.fieldTitle} >{`Attack Range`}</Text>
                        <TextInput style={appStyles.fieldLong} value={AttackRange} onChangeText={setAttackRange} ></TextInput>
                        
                        <Text style={appStyles.fieldTitle} >{`Slash Damage 1`}</Text>
                        <TextInput style={appStyles.fieldLong} value={SlashDamage1} onChangeText={setSlashDamage1} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`Slash Damage 2`}</Text>
                        <TextInput style={appStyles.fieldLong} value={SlashDamage2} onChangeText={setSlashDamage2} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`AOE damage`}</Text>
                        <TextInput style={appStyles.fieldLong} value={AOEDamage} onChangeText={setAOEDamage} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{`AOE radius`}</Text>
                        <TextInput style={appStyles.fieldLong} value={AOERadius} onChangeText={setAOERadius} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitleBigger} >{"For Leader"}</Text>
                        <View style={styles.spaceSmall} />
                        
                        <Text style={appStyles.fieldTitle} >{` `}</Text>
                        <TextInput style={appStyles.fieldLong} value={AttackRangeLeader} onChangeText={setAttackRangeLeader} ></TextInput>
                        
                        <Text style={appStyles.fieldTitle} >{` `}</Text>
                        <TextInput style={appStyles.fieldLong} value={SlashDamage1Leader} onChangeText={setSlashDamage1Leader} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{` `}</Text>
                        <TextInput style={appStyles.fieldLong} value={SlashDamage2Leader} onChangeText={setSlashDamage2Leader} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{` `}</Text>
                        <TextInput style={appStyles.fieldLong} value={AOEDamageLeader} onChangeText={setAOEDamageLeader} ></TextInput>
                        <Text style={appStyles.fieldTitle} >{` `}</Text>
                        <TextInput style={appStyles.fieldLong} value={AOERadiusLeader} onChangeText={setAOERadiusLeader} ></TextInput>
                    </View>
                </View>
                
                <View style={styles.space} />

                {/* LEADER ABILITY */}
                <Text style={appStyles.fieldTitleBigger} >{"Leader Ability Effect"}</Text>
                <View style={styles.space} />

                <View style={styles.horz}>
                    <View>
                        <Text style={appStyles.fieldTitleBig} >{`All Mecha units gain:`}</Text>
                        <TextInput style={appStyles.fieldLong} value={MovementBuffValue} onChangeText={setMovementBuffValue} ></TextInput>
                    </View>
                    <View style={styles.space} />
                    <View>
                        <Text style={appStyles.fieldTitleBig} >{`% movement speed for:`}</Text>
                        <TextInput style={appStyles.fieldLong} value={MovementBuffDuration} onChangeText={setMovementBuffDuration} ></TextInput>
                        <Text style={appStyles.fieldTitleBig} >{`seconds`}</Text>
                    </View>
                </View>


            </View>
        
        
        }
        </View>
    );
}


const styles = StyleSheet.create({
    horz: {
        flexDirection: 'row', alignItems: 'stretch'
    },
    space: {
        width: '6mm', height: '6mm'
    },
    spaceSmall: {
        width: '3mm', height: '3mm'
    },
    fieldTitleBig: {
        fontSize: 12, fontWeight: 'bold', color: '#444'
    }
});