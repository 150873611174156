
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, TouchableWithoutFeedback, View, Text, TouchableOpacity, TextInput, Button, Platform
    , Keyboard, StyleSheet, Image, ActivityIndicator, FlatList, Switch } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';
import api from '../api/api';
import { Actions } from '../contexts/actions';



export const MSwitch = (props) => {

    const { choseables, multi, value, onValueChange } = props;

    const isSelected = (p) => {
        if (multi) {
            return (value ?? '').includes(`${p}|`);
        }
        else { return p == value; }
    }


    const getBorder = (i) => {
        let _l = (choseables ?? []).length;
        let _r = '8mm';
        return {
            borderTopLeftRadius: i == 1 ? _r : '',
            borderBottomLeftRadius: i == 1 ? _r : '',
            borderTopRightRadius: i == _l ? _r : '',
            borderBottomRightRadius: i == _l ? _r : '',
            paddingStart: i == 1 ? '1.5mm' : undefined,
            paddingEnd: i == _l ? '1.5mm' : undefined,
            borderLeftWidth: i == 1 ? 1 : 0,
            borderRightColor: i == _l ? undefined : '#00968899'
        }
    }


    let idx = 0;
    return (
        <View style={styles.rows} >
        {(choseables ?? []).map((p, k) => { 
            idx++;
            return (
            <TouchableOpacity key={p} onPress={() => onValueChange(p)} 
                // @ts-ignore
                style=
                {{ ...styles.item, ...(isSelected(p) ? styles.itemSelected : {}), ...getBorder(idx)}} 
                >
                <Text 
                // @ts-ignore
                style=
                {{ ...styles.itemLabel, ...(isSelected(p) ? styles.itemLabelSelected : {})}} >
                    {p}
                </Text>
            </TouchableOpacity>
        )})}
        </View>
    );

}



const styles = StyleSheet.create({
    rows: {
        flexDirection: 'row', alignItems: 'center'
    },
    item: { 
        // @ts-ignore
        borderRadius: '8mm', 
        backgroundColor: 'white', borderWidth: 1, borderColor: '#009688'
    },
    itemSelected: { backgroundColor: '#009688' },
    itemLabel: { fontWeight: 'bold', color: '#009688', marginVertical: '1mm', marginHorizontal: '1mm' },
    itemLabelSelected: { color: 'white' }
});